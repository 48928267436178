import React, { useState } from 'react';
import { Box, Alert, CircularProgress, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox, Autocomplete, TextField, LinearProgress, IconButton, Tooltip } from '@mui/material';
import Swal from 'sweetalert';
import verificareToken from './VerificareToken';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';

const InchidereDosare = ({ dosare }) => {
    const { token } = verificareToken();
    const [luniInchidere, setLuniInchidere] = useState(6);
    const [inchidereDosare, setInchidereDosare] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);  
    const [progress, setProgress] = useState(0);
    
    const inchideDosare = async () => {
        const chunkSize = 10;
        let totalInchise = 0;
        let processedCount = 0;

        setIsProcessing(true);

        for (let i = 0; i < dosare.length; i += chunkSize) {
            const chunk = dosare.slice(i, i + chunkSize);
            const responses = await Promise.all(chunk.map(dosar =>
                fetch(`${process.env.REACT_APP_API_URL}inchideDosare?idDosar=${dosar.id}&months=${luniInchidere}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }).then(res => {
                    processedCount++;
                    const progressValue = Math.round((processedCount / dosare.length) * 100);
                    setProgress(progressValue);

                    if (res.status === 200) {
                        totalInchise++;
                    }
                }).catch(error => {
                    console.error(`Eroare la inchiderea dosarului ${dosar.id}:`, error);
                })
            ));
        }

        setIsProcessing(false);
        setModalOpen(false);

        Swal({
            title: 'Închidere dosare completă',
            text: totalInchise === 1 
                ? '1 dosar a fost închis.' 
                : `${totalInchise} dosare au fost închise.`,
            icon: 'success',
        });
        
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleOpenModal = () => {
        //console.log('dosarele trimise:', dosare);
        setModalOpen(true);
    };

    return (
        <div>
            <Tooltip title="Închidere dosarele din listă (filtrate) mai vechi de o anumită perioadă de timp">
                <IconButton onClick={handleOpenModal}>
                    <RuleFolderIcon />
                </IconButton>
            </Tooltip>

            <Dialog open={modalOpen} fullWidth maxWidth="md">
                <DialogTitle className='titluModal'>
                    <RuleFolderIcon /> Închidere dosare
                </DialogTitle>
                
                <DialogContent className='mt-3'>
                    {isProcessing && (
                        <>
                        <Box display="flex" flexDirection="column" alignItems="center"  className="mt-2">
                            <Typography variant="body1">Închidere dosare mai vechi de {luniInchidere} luni: {progress}%</Typography>
                            <LinearProgress
                                variant="determinate" 
                                value={progress}
                                style={{ width: '100%', marginBottom: '20px', height: '20px' }}
                            />
                        </Box>
                        </>
                    )}
                    {!isProcessing && (
                       <>
                        <Box display="flex" flexDirection="column" alignItems="center" className="mt-2">
                            <FormControlLabel
                                control={<Checkbox checked={inchidereDosare} onChange={(e) => setInchidereDosare(e.target.checked)} />}
                                label={(
                                    <Box display="flex" alignItems="center">
                                        Închidere dosare cu termene mai vechi de &nbsp;
                                        <Autocomplete
                                            options={[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                                            getOptionLabel={(option) => `${option} luni`}
                                            value={luniInchidere}
                                            onChange={(event, newValue) => setLuniInchidere(newValue)}
                                            renderInput={(params) => <TextField className='labelLuni' {...params} label="Luni" size="small" />}
                                            disableClearable
                                            style={{ width: 120 }}
                                        />
                                    </Box>
                                )}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" className="mt-4">
                            <Alert severity='warning'>
                            {dosare.length === 1 
                                ? `Se va transmite spre închidere, în funcție de termen, 1 dosar.` 
                                : `Se vor transmite spre închidere, în funcție de termen, ${dosare.length} dosare.`}
                            </Alert>
                        </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="error"
                        onClick={handleCloseModal}
                        disabled={isProcessing}
                    >
                        Renunțare
                    </Button>
                    <Button 
                        onClick={inchideDosare} 
                        color='warning' 
                        variant="contained" 
                        disabled={!inchidereDosare || isProcessing}
                    >
                        Închideți dosarele
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InchidereDosare;
